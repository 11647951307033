<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.tipo_calibracao.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.tipo_calibracao.titulos.novo')"
      :sem-botao-ok="!participanteLogadoConta && !buscarPermissao('TipoCalibracao', 'Inserir')"
      :sem-filtro="!buscarPermissao('TipoCalibracao', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir
        && participanteLogadoConta
        && buscarPermissao('TipoCalibracao', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      :disabled="!participanteLogadoConta"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao

      v-model="tabela.selecionados"
      :dados="tabela.dados"
      :mostrar-seletor="buscarPermissao('TipoCalibracao', 'Deletar')"
      class="mt-2"
      :colunas="colunasTabela"
      :mostrar-checkbox="participanteLogadoConta"
      :sem-acoes="!participanteLogadoConta"
      sem-paginacao
    >
      <template
        v-if="participanteLogadoConta"
        v-slot:item.acoes="{ item }"
      >
        <dropdown-padrao
          v-if="buscarPermissao('TipoCalibracao', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item

              text
              color="secondary"
              @click="abrirEditar(item.item)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
      <template v-slot:item.nome="{ item }">
        <td class="coluna-nome">
          {{ item.item.nome }}
        </td>
      </template>
      <template v-slot:item.descricao="{ item }">
        <td class="coluna-descricao">
          {{ item.item.descricao }}
        </td>
      </template>
    </tabela-padrao>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import TipoCalibracaoService from '@common/services/cadastros/TipoCalibracaoService.js';
import Filtro from './filtro.vue';
import helpers from '@common/utils/helpers';
import { mapGetters } from 'vuex';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [],
        quantidadeItens: 0,
      },
      filtroAplicado: { filter: '' },

    };
  },
  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
    colunasTabela: function () {
      const colunas = [
        {
          value: 'acoes',
          text: '',
          width: 0,
        },
        {
          value: 'nome',
          text: this.$t('modulos.tipo_calibracao.tabela.nome'),
          sortable: true,
          width: 0,
        },
        {
          value: 'descricao',
          text: this.$t('modulos.tipo_calibracao.tabela.descricao'),
          sortable: true,
          width: 0,
        },
        {
          value: 'acessivelClientes',
          text: this.$t('modulos.tipo_calibracao.tabela.acessivel_cliente'),
          sortable: true,
          formatter: (v) => {
            return helpers.BoleanoEnum.find((el) => el.value === v).text;
          },
        },
      ];

      if (this.participanteLogadoConta) {
        return [...colunas];
      }
      colunas.shift();
      return [...colunas];
    },
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.tipo_calibracao.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(this, funcionalidade, funcionalidadeAcao);
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function () {
      let parametros = {
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoCalibracaoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data;
          this.tabela.quantidadeItens = res.data.length;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: async function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        let promises = [];
        this.tabela.selecionados.forEach((registro) => {
          promises.push(TipoCalibracaoService.excluir(registro.id));
        });

        Promise.all(promises)
          .then(() => {
            this.listarRegistros();
            this.toastSucesso(
              this.$t(`modulos.tipo_calibracao.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'tipo-calibracao-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'tipo-calibracao-editar',
        params: { id: item.id },
      });
    },
  },
};
</script>

<style lang="scss">
.coluna-nome {
  max-width: 26vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 0 !important;
}

@media only screen and (max-width:850px) {
  .coluna-nome {
    max-width: 15vw;
  }
}

@media only screen and (min-width:851px) and (max-width:1500px) {
  .coluna-nome {
    max-width: 20vw;
  }
}

.coluna-descricao {
  max-width: 50vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 0 !important;
}

@media only screen and (max-width:850px) {
  .coluna-descricao {
    max-width: 25vw;
  }
}

@media only screen and (min-width:851px) and (max-width:1500px) {
  .coluna-descricao {
    max-width: 40vw;
  }
}

.v-data-table__wrapper {
  overflow-x: hidden;
}
</style>
